<template>
  <div>
    <HeaderMain
      subtitle=""
      title="Контакты"
    />
    <!--    <AlertError v-if="get_check_filled && !get_check_filled.contactCheck">-->
    <!--      <p>Заполнены не все данные контактного лица</p>-->
    <!--    </AlertError>-->
    <!--    <AlertError v-if="get_check_filled && !get_check_filled.filesCheck">-->
    <!--      <p>Загружены не все файлы</p>-->
    <!--    </AlertError>-->
    <WrapperPreviewCategory customClass="section-preview_lk">
      <WrapperCollapse
        :initialOpen="true"
        :title="titleTextLet"
      >
        <!--                  :blocked="get_blockedEnterData"-->
        <ContainerPersonContact
          :can-be-deleted="false"
          :contact="mainContact"
          :ignore-personal-phone-number="true"
          :is-single="true"
          @change-contact="changeMainContact($event)"
        />
      </WrapperCollapse>
    </WrapperPreviewCategory>
    <WrapperPreviewCategory customClass="section-preview_lk">
      <WrapperCollapse
        :initialOpen="true"
        :title="titleTextLet2"
      >
        <!--                  :blocked="get_blockedEnterData"-->
        <ContainerPersonContact
          :can-be-deleted="false"
          :contact="contacts[0]"
          :ignore-personal-phone-number="true"
          :is-single="true"
          @change-contact="changeContact($event)"
        />
      </WrapperCollapse>
    </WrapperPreviewCategory>
  </div>
</template>

<script>
import request from "@/services/request";
// import moment from 'moment';
import {mapActions, mapGetters} from 'vuex';
import WrapperPreviewCategory from '@/components/wrappers/WrapperPreviewCategory';
import WrapperCollapse from '@/components/wrappers/WrapperCollapse';
// import AlertError from '@/components/alerts/AlertError';
import HeaderMain from '@/components/headers/HeaderMain';
import ContainerPersonContact from '@/containers/private/ContainerPersonContact';

// moment.locale('ru');

export default {
  name: "PropContact",
  components: {
    WrapperPreviewCategory,
    WrapperCollapse,
    HeaderMain,
    ContainerPersonContact,
    // AlertError,
  },
  data() {
    return {
      // convertAcceptInfo: null,
      // convertAccepted: false,
      loadingBlockEnterData: false,
      titleTextLet: null,
      titleTextLet2: null,
      mainContact: {
        fio: '',
        email: '',
        position: '',
        workPhoneNumber: '',
        personalPhoneNumber: '',
      },
      contacts: [
        {
          fio: '',
          email: '',
          position: '',
          workPhoneNumber: '',
          personalPhoneNumber: '',
        },
      ]
    }
  },
  mounted() {
    if (this.getAuthData.authenticated && this.getAuthData && !this.getAuthUserDataLoading) {
      this.getContacts();
      this.getMainContact();
      this.titleText();
    }
  },
  watch: {
    getAuthUserDataLoading(newValue) {
      if (newValue === false) {
        this.getContacts()
        this.getMainContact()
      }
    }
  },
  computed: {
    ...mapGetters('checkFilled', {get_check_filled_status: 'get_check_filled_status'}),
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles',
      isRegion: 'isRegion'
    }),
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
    
    titleText() {
      if (this.getUserRoles.find(el => el === 'REGION_ROLE')) {
        this.titleTextLet = 'Ответственное должностное лицо высшего органа исполнительной власти субъекта Российской Федерации'
        this.titleTextLet2 = 'Непосредственный ответственный исполнитель'
      } else if (this.getUserRoles.find(el => el === 'FOUNDER_ROLE')) {
        this.titleTextLet = 'Ответственное должностное лицо федерального органа исполнительной власти'
        this.titleTextLet2 = 'Непосредственный ответственный исполнитель'
      }
    },
    
    async getMainContact() {
      let uri = `/api/person/official/getAllFoivId?founderId=${this.getUser.founderID}`
      if (this.isRegion) uri = `/api/person/official/getAllRegionId?regionId=${this.getUser.regionID}`
      const res = await request({
        endpoint: uri,
        method: 'get',
        token: `${this.getAuthData.token}`
      })
      if (res.status === 200) {
        if (res.data?.personId > 0) {
          const bufRes = res.data;
          for (const key of Object.keys(res.data)) {
            bufRes[key] = bufRes[key] === null ? '' : bufRes[key]
          }
          this.mainContact = bufRes
          await this.fetchCheckFilled({mainContact: this.mainContact, contacts: this.contacts})
        }
      } else {
        if (res.status !== 404)
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка получения данных ответственных исполнителей',
            timeout: 5000
          })
      }
    },
    
    async getContacts() {
      let uri = `/api/person/direct/getAllFoivId?founderId=${this.getUser.founderID}`
      if (this.isRegion) uri = `/api/person/direct/getAllRegionId?regionId=${this.getUser.regionID}`
      const res = await request({
        endpoint: uri,
        method: 'get',
        token: `${this.getAuthData.token}`
      })
      if (res.status === 200) {
        if (res.data.length > 0) {
          this.contacts = res.data.map(el => {
            for (const key of Object.keys(el)) {
              el[key] = el[key] === null ? '' : el[key]
            }
            return el
          });
          await this.fetchCheckFilled(
            {mainContact: this.mainContact, contacts: this.contacts}
          )
        }
      } else {
        if (res.status !== 404)
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка получения данных ответственных исполнителей',
            timeout: 5000
          })
      }
    },
    
    async changeMainContact(obj) {
      console.log(obj);
      const res = await request({
        endpoint: `/api/person/official/${obj?.personId ? 'update' : 'add'}`,
        method: 'post',
        token: `${this.getAuthData.token}`,
        body: {
          ...obj,
          founderId: this.getUser.founderID,
          regionId: this.getUser.regionID,
          personId: obj?.personId,
        },
      })
      if (res.status === 200) {
        if (res.data) {
          this.mainContact = res.data
        }
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения данных ответственного должностного лица высшего органа исполнительной власти',
          timeout: 5000
        })
      }
    },
    
    async changeContact(obj, index) {
      const res = await request({
        endpoint: `/api/person/direct/${obj?.personId ? 'update' : 'add'}`,
        method: 'post',
        token: `${this.getAuthData.token}`,
        body: {
          ...obj,
          founderId: this.getUser.founderID,
          regionId: this.getUser.regionID,
          personId: obj?.personId,
        },
      })
      if (res.status === 200) {
        console.log('успешно');
        await this.getContacts();
        this.contacts.splice(index, 1, res.data)
      } else {
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения данных ответственного исполнителя ' + (index + 1),
          timeout: 5000
        })
      }
    },
  }
}

</script>

<style lang="sass">
.section-preview_lk
  background: #fff
  border-radius: 10px
  border: 1px solid #cdd1d4
  margin-bottom: 20px

.lk-list_flex_start
  justify-content: flex-start
</style>